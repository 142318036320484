import React from "react";
import "../App.css";

const resumeURL = "https://drive.google.com/file/d/1rBi05dDQCr5gM3cMVTDqOLcDHmOVjvCk/view?usp=sharing";

const Contact = () => (
  <section className="section">
    <h2>Contacts</h2>
    <div className="card">
      <ul>
        <li>
          <a href={resumeURL} target="_blank" rel="noopener noreferrer">
            <strong>Resume</strong>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/ryan-mc-kim"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>LinkedIn</strong>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/ryanmckim"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>GitHub</strong>
          </a>
        </li>
        <li>
          <a
            href="https://devpost.com/mck649?ref_content=user-portfolio&ref_feature=portfolio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Devpost</strong>
          </a>
        </li>
        <li>
          <a href="mailto:mck649@gmail.com">
            <strong>Email</strong>
          </a>
        </li>
      </ul>
    </div>
  </section>
);

export default Contact;
